import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'plusdigital/empresa',
    loadChildren: () => import('./download/download.module').then(m => m.DownloadModule)
  },
  {
    path: 'plusdigital/consumidor',
    loadChildren: () => import('./download/download.module').then(m => m.DownloadModule)
  },
  {
    path: '**',
    redirectTo: 'plusdigital/consumidor'
  }
];

@NgModule({
  declarations: [
  ],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
